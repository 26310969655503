// Copyright © 2023 The Things Industries B.V.

import React from 'react'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import Icon, { IconMessageReport, IconCheck } from '@ttn-lw/components/icon'
import Button from '@ttn-lw/components/button'

import Message from '@ttn-lw/lib/components/message'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import { selectPluginTTSCloud } from '@ttn-lw/lib/selectors/env'

import { selectSubscriptionProduct } from '@console/store/selectors/subscription-management.tti'
import { selectOwnTenant } from '@console/store/selectors/tenant.tti'

import { REGULAR_CLOUD_PLANS, UPGRADE, bulletpoints } from './utils.tti'

import styles from './network-information.styl'

const m = defineMessages({
  discoveryUsedPlan: 'This network uses <b>Cloud Discovery</b>',
  discoveryUpgradeToPlan: 'Lift restrictions with <b>Cloud Standard</b>',
  standardUsedPlan: 'This network uses <b>Cloud Standard</b>',
  standardUpgradeToPlan: 'Unlock new features with <b>Cloud Plus</b>',
  plusUsedPlan: 'This network uses <b>Cloud Plus</b>',
  alternativeSymbol: 'This network runs on The Things Stack Cloud',
  discoveryMaxDevices: '10 devices max',
  discoveryMaxApplicationsGateways: '10 gateways, 1 application max',
  dicoveryUptime: '99.9% guaranteed uptime',
  dicoveryEvaluation: 'For evaluation and proof-of-concepts',
  manageSubscriptionButton: 'Manage cloud subscription',
  plansButton: 'See all cloud plans',
})

const SubscriptionInfo = () => {
  const subscriptionInfo = useSelector(selectSubscriptionProduct)
  const productSymbol = subscriptionInfo.product.symbol
  const isAlternativeProductSymbol = !REGULAR_CLOUD_PLANS.includes(productSymbol)
  const upgradeTo = UPGRADE[productSymbol]
  const isDiscovery = subscriptionInfo.product.symbol === 'DISCOVERY'
  const isPlus = subscriptionInfo.product.symbol === 'PLUS'
  const subscriptionManagementUrl = useSelector(selectPluginTTSCloud).subscription_management_url
  const plansPageUrl = useSelector(selectPluginTTSCloud).plans_page_url
  const tenantStripe = useSelector(selectOwnTenant)?.billing?.stripe
  const subscriptionId = tenantStripe?.subscription_id
  const upgradeToMessage = m[`${productSymbol.toLowerCase()}UpgradeToPlan`]
  const usedPlanMessage = m[`${productSymbol.toLowerCase()}UsedPlan`]

  return (
    <>
      {/* Current plan info section */}
      <div className={classNames(styles.subscriptionInfo, 'mt-ls-s')}>
        <div>
          <Message
            content={isAlternativeProductSymbol ? m.alternativeSymbol : usedPlanMessage}
            values={{
              b: msg => <b>{msg}</b>,
            }}
            component={'p'}
            className="mt-0"
          />
          {isDiscovery ? (
            <>
              <Icon icon={IconMessageReport} className="c-warning" />{' '}
              <Message content={m.discoveryMaxDevices} /> <br />
              <Icon icon={IconMessageReport} className="c-warning" />{' '}
              <Message content={m.discoveryMaxApplicationsGateways} />
              <br />
              <Icon icon={IconCheck} className="c-success" /> <Message content={m.dicoveryUptime} />
              <br />
              <Icon icon={IconCheck} className="c-success" />{' '}
              <Message content={m.dicoveryEvaluation} />
              <br />
            </>
          ) : (
            <>
              {isAlternativeProductSymbol
                ? bulletpoints.STANDARD.map((item, i) => (
                    <div key={i}>
                      <Icon icon={IconCheck} className="c-success" /> <Message content={item} />
                    </div>
                  ))
                : bulletpoints[productSymbol].map((item, i) => (
                    <div key={i}>
                      <Icon icon={IconCheck} className="c-success" /> <Message content={item} />
                    </div>
                  ))}
            </>
          )}
          <Button.AnchorLink
            href={`${subscriptionManagementUrl}/dashboard/subscriptions`}
            className="mt-cs-l"
            message={m.manageSubscriptionButton}
            target="_blank"
          />
        </div>
      </div>
      {/* Upgrade info section */}
      {!isPlus && !isAlternativeProductSymbol && (
        <div className={styles.subscriptionInfo}>
          <div>
            <Message
              content={upgradeToMessage}
              values={{
                b: msg => <b>{msg}</b>,
              }}
              component={'p'}
              className="mt-0"
            />
            {bulletpoints[upgradeTo].map((item, i) => (
              <div key={i}>
                <Icon icon={IconCheck} className="c-success" /> <Message content={item} />
              </div>
            ))}
            <Button.AnchorLink
              className="mt-cs-l mr-cs-s"
              primary
              message={sharedMessages.upgradeNow}
              href={`${subscriptionManagementUrl}/dashboard/subscriptions/${subscriptionId}`}
              target="_blank"
            />
            <Button.AnchorLink
              href={plansPageUrl}
              className="mt-cs-l"
              message={m.plansButton}
              target="_blank"
            />
          </div>
        </div>
      )}
    </>
  )
}

export default SubscriptionInfo
