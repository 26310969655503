// Copyright © 2024 The Things Industries B.V.

import React from 'react'
import { defineMessages } from 'react-intl'

import Icon, { IconUserPlus } from '@ttn-lw/components/icon'
import Link from '@ttn-lw/components/link'

import Message from '@ttn-lw/lib/components/message'

import PropTypes from '@ttn-lw/lib/prop-types'

import ContentTemplate from './template'

const m = defineMessages({
  title: 'Support access has been requested',
  body: 'A support engineer has created a login to your <code>{tenantID}</code> tenant. This login is for a dedicated support user and provides read-only permissions to all resources in your tenant. Typically, the support engineer needs read-only access to better provide help to a support ticket you or another administrator have filed.',
  closing:
    'As an administrator, you can manage the support user via <Link>User Management</Link> in the Admin Portal of the Console.',
  preview: 'Read-only access has been requested by the support team.',
})

const SupportAccessRequestedIcon = () => <Icon icon={IconUserPlus} className="c-tts-primary" />

const SupportAccessRequestedPreview = () => <Message content={m.preview} />

const SupportAccessRequestedTitle = () => <Message content={m.title} />

const SupportAccessRequested = ({ notificationData }) => {
  const { tenant_id } = notificationData.data
  const messages = {
    body: m.body,
    action: m.closing,
  }
  const values = {
    body: {
      tenantID: tenant_id,
    },
    action: {
      Link: msg => <Link to={'/admin-panel/user-management/support'}>{msg}</Link>,
    },
  }
  return <ContentTemplate messages={messages} values={values} />
}

SupportAccessRequested.propTypes = {
  notificationData: PropTypes.notificationData.isRequired,
}

SupportAccessRequested.Title = SupportAccessRequestedTitle
SupportAccessRequested.Preview = SupportAccessRequestedPreview
SupportAccessRequested.Icon = SupportAccessRequestedIcon

export default SupportAccessRequested
