// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'
import { useSelector } from 'react-redux'

import SubscriptionNudgeComponent from '@ttn-lw/components/sidebar/side-footer/subscription-nudge'

import RequireRequest from '@ttn-lw/lib/components/require-request'

import { getRegistryTotals } from '@console/store/actions/tenant.tti'

import { selectSubscriptionProduct } from '@console/store/selectors/subscription-management.tti'

const SubscriptionNudge = () => {
  const subscriptionInfo = useSelector(selectSubscriptionProduct)
  const isManualBilling = subscriptionInfo && subscriptionInfo.product.symbol === 'MANUAL-BILLING'

  if (!subscriptionInfo || isManualBilling) {
    return null
  }
  return (
    <RequireRequest requestAction={getRegistryTotals()}>
      <SubscriptionNudgeComponent />
    </RequireRequest>
  )
}

export default SubscriptionNudge
